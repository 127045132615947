import {
  Box,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Toolbar,
} from "@mui/material";
import React from "react";
import Layout from "../components/Layout";
import { graphql } from "gatsby";

const drawerWidth = 240;

export const pageQuery = graphql`
  query {
    allMarkdownRemark(sort: { fields: fileAbsolutePath }) {
      edges {
        node {
          id
          html
          headings(depth: h1) {
            value
            depth
            id
          }
        }
      }
    }
  }
`;

const DocumentationPage = ({
  data,
}: {
  data: {
    allMarkdownRemark: {
      edges: [
        {
          node: {
            id: string;
            html: string;
            headings: Array<{ depth: number; value: string; id: string }>;
          };
        }
      ];
    };
  };
}) => {
  const {
    allMarkdownRemark: { edges },
  } = data;

  return (
    <Layout>
      <Box sx={{ display: "flex" }}>
        <Drawer
          variant="permanent"
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            [`& .MuiDrawer-paper`]: {
              width: drawerWidth,
              boxSizing: "border-box",
            },
          }}
        >
          <Toolbar />
          <Box sx={{ overflow: "auto" }}>
            <List>
              {edges.map(({ node }) => (
                <>
                  {node.headings.map((h) => (
                    <ListItem key={h.id} button component="a" href={`#${h.id}`}>
                      <ListItemText
                        sx={{ marginLeft: h.depth - 1 }} // From 1
                        primary={h.value}
                      />
                    </ListItem>
                  ))}
                </>
              ))}
            </List>
          </Box>
        </Drawer>

        <Box component="main" sx={{ flexGrow: 1, p: 3 }} maxWidth="md">
          {edges.map(({ node }) => (
            <Box
              sx={{ mb: 7 }}
              component="section"
              key={node.id}
              dangerouslySetInnerHTML={{ __html: node.html }}
            ></Box>
          ))}
        </Box>
      </Box>
    </Layout>
  );
};

export default DocumentationPage;
